var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-gray-100 min-h-screen"},[_c('div',{staticClass:"container max-w-xl mx-auto flex flex-col items-center justify-center px-2 py-6"},[_c('div',{staticClass:"mt-12 w-full"},[_c('vue-typed-js',{attrs:{"showCursor":false,"backSpeed":35,"typeSpeed":25,"smartBackspace":true,"strings":['Estamos procesando tus pagos con tarjeta 💳. Pronto calcularemos tus gastos.']},on:{"onComplete":_vm.finishFirstParagraph}},[_c('h2',{staticClass:"typing inline font-mono"})]),_vm._v(" "),(_vm.firstParagraphFinished)?_c('vue-typed-js',{attrs:{"showCursor":false,"backSpeed":35,"typeSpeed":25,"startDelay":3000,"smartBackspace":true,"strings":['Nos podemos demorar unos segundos 🕗.', 'Nos podemos demorar unos minutos 🕔, puedes ir a hacerte un café por mientras ☕️.']},on:{"onComplete":_vm.finishSecondParagraph}},[_c('h2',{staticClass:"mt-6 typing font-mono"})]):_vm._e(),_vm._v(" "),(_vm.secondParagraphFinished)?_c('vue-typed-js',{attrs:{"showCursor":false,"startDelay":3000,"backSpeed":35,"typeSpeed":25,"smartBackspace":true,"strings":['Ok, nos está tomando un poco más 😅.', 'Ok, nos está tomando más de lo que pensabamos 😐.']},on:{"onComplete":_vm.finishThirdParagraph}},[_c('h2',{staticClass:"mt-6 typing font-mono"})]):_vm._e(),_vm._v(" "),(_vm.thirdParagraphFinished)?_c('vue-typed-js',{attrs:{"loop":true,"startDelay":3000,"backDelay":3000,"showCursor":false,"backSpeed":35,"typeSpeed":25,"smartBackspace":true,"strings":['No te hemos abandonado, seguimos trabajando 🚧.',
                   'Paciencia.',
                   '¿Quizás otro café ☕️?',
                   'Ya estamos casi.',
                   '¿Sabías que la Universidad de Oxford es más antigua que la cultura Azteca 😱?',
                   '¿Sabías que el elefante es el único animal del mundo con 4 rodillas 😱?',
                   '¿Sabías que Fintoc nació en marzo del 2020 😏?',
                   'Estamos haciendo cálculos muy complicados 🧮.',
                   'Me quedo sin ideas, pero probablemente falta muy poco 🕞.',
                   'Se me ocurrieron un par más! 💡' ]},on:{"onComplete":_vm.finishSecondParagraph}},[_c('h2',{staticClass:"mt-6 typing font-mono"})]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }